@font-face {
    font-family: 'Geomanist';
    src: url('Geomanist-Regular.woff2') format('woff2'),
        url('Geomanist-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geomanist';
    src: url('Geomanist-Medium.woff2') format('woff2'),
        url('Geomanist-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geomanist Book';
    src: url('Geomanist-Book.woff2') format('woff2'),
        url('Geomanist-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}