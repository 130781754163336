@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  font-size: 16px;
}
@media (max-width: 1800px) {
  html, body {
    font-size: 14px;
  }
}
@media (max-width: 1500px) {
  html, body {
    font-size: 13px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table thead tr {
  border-bottom: 1px solid rgba(249, 249, 249, 0.5);
}

table tbody tr {
  border-bottom: 1px solid rgba(249, 249, 249, 0.25);
}

table td {
  padding: 15px 0;
}

@media (max-width: 1700px) {
  .result-container {
    margin-right: 20rem;
  }
}

.popup{
    transform: translate(0 -200);
    padding: 3px;
    color: rgb(252 125 125);
    visibility:visible;
    transition: all 0.2s;
} 
.popup-invisible{
    transform: translate(0 -200);
    padding: 3px;
    /* visibility:hidden; */
    /* color: rgb(252 225 225); */
    transition: all 0.2s;
};

/* Loading Indicator styling from https://loading.io/css/ as CC0 */
.lds-facebook,
.lds-facebook div {
  box-sizing: border-box;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: currentColor;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}